@font-face {
  font-family: "New Rail Alphabet";
  src: url(/fonts/NewRailAlphabet-LightWEB.eot?#iefix);
  src: url(/fonts/NewRailAlphabet-LightWEB.eot?#iefix) format("eot"),
    url(/fonts/NewRailAlphabet-LightWEB.woff2) format("woff2"),
    url(/fonts/NewRailAlphabet-LightWEB.woff) format("woff"),
    url(/fonts/NewRailAlphabet-LightWEB.ttf) format("truetype");
  font-weight: 200;
}

// Not in use
// @font-face {
//   font-family: "New Rail Alphabet";
//   src: url(/fonts/NewRailAlphabet-MediumWEB.eot?#iefix);
//   src: url(/fonts/NewRailAlphabet-MediumWEB.eot?#iefix) format("eot"),
//     url(/fonts/NewRailAlphabet-MediumWEB.woff2) format("woff2"),
//     url(/fonts/NewRailAlphabet-MediumWEB.woff) format("woff"),
//     url(/fonts/NewRailAlphabet-MediumWEB.ttf) format("truetype");
//   font-weight: 400;
// }

@font-face {
  font-family: "New Rail Alphabet";
  src: url(/fonts/NewRailAlphabet-BoldWEB.eot?#iefix);
  src: url(/fonts/NewRailAlphabet-BoldWEB.eot?#iefix) format("eot"),
    url(/fonts/NewRailAlphabet-BoldWEB.woff2) format("woff2"),
    url(/fonts/NewRailAlphabet-BoldWEB.woff) format("woff"),
    url(/fonts/NewRailAlphabet-BoldWEB.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "New Rail Alphabet";
  src: url(/fonts/NewRailAlphabet-BlackWEB.eot?#iefix);
  src: url(/fonts/NewRailAlphabet-BlackWEB.eot?#iefix) format("eot"),
    url(/fonts/NewRailAlphabet-BlackWEB.woff2) format("woff2"),
    url(/fonts/NewRailAlphabet-BlackWEB.woff) format("woff"),
    url(/fonts/NewRailAlphabet-BlackWEB.ttf) format("truetype");
  font-weight: 800;
}

@import "font.scss";
@import "colors.scss";

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: $grey;
}
body,
input,
select,
textarea,
button {
  font-family: "New Rail Alphabet", -apple-system, system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.3px;
}
body.touch {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

#root {
  max-width: 600px;
  margin: 0 auto;
  padding-top: env(safe-area-inset-top);
  text-align: center;
}
.admin #root {
  max-width: 100%;
}
a {
  color: #000;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
img {
  display: block;
  width: 100%;
  height: auto;
}
.bold {
  font-weight: 600;
  letter-spacing: 0;
}
.danger {
  color: $danger;
}
.meta {
  color: $meta;
}
.round {
  border-radius: 100%;
}
.align-center {
  text-align: center;
}
.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
// Full height - used in lineup position
.full-height,
.full-height body,
.full-height #root,
.full-height #root > div {
  height: 100%;
  min-height: 100%;
}
// Relative overlay - used in player selector to handle input fields
.PageOverlay-relative {
  #root {
    display: none;
  }
  #react-player-selector .PageOverlay {
    position: relative;
  }
}
